var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-spin",
    {
      staticStyle: { height: "100%", "background-color": "rgb(217, 220, 224)" },
      attrs: { spinning: _vm.spinning },
    },
    [
      _c(
        "div",
        { staticStyle: { height: "100%", overflow: "hidden" } },
        [
          _c(
            "a-row",
            { staticClass: "fun", staticStyle: { overflow: "hidden" } },
            [
              _c(
                "a-col",
                {
                  staticClass: "fun-left",
                  style: _vm.screenWidth > 900 ? "" : "padding-left:0",
                  attrs: { span: _vm.screenWidth > 700 ? 18 : 24 },
                },
                [
                  _vm.screenWidth > 900
                    ? _c("div", { staticClass: "logo" }, [
                        _c("img", {
                          attrs: { src: _vm.logo, alt: "logo" },
                          on: { click: _vm.goToHome },
                        }),
                        _c("span", [_vm._v("图像对比")]),
                      ])
                    : _vm._e(),
                  _c("a-icon", {
                    class: _vm.pageNum <= 1 ? "icon-color" : "",
                    staticStyle: {
                      "margin-right": "15px",
                      "font-size": "14px",
                    },
                    attrs: { type: "left-circle" },
                    on: {
                      click: () => {
                        if (this.imgLefts.length > 0) {
                          this.pageNum > 1 && --this.pageNum
                        } else {
                          this.pageNum > 1 && --this.pageNum
                        }
                      },
                    },
                  }),
                  _c("a-icon", {
                    class:
                      _vm.pageNum == _vm.imgLefts.length ||
                      _vm.pageNum == _vm.imgRights.length
                        ? "icon-color"
                        : "",
                    staticStyle: {
                      "margin-right": "15px",
                      "font-size": "14px",
                    },
                    attrs: { type: "right-circle" },
                    on: {
                      click: () => {
                        if (this.imgLefts.length > 0) {
                          this.pageNum < this.imgLefts.length && ++this.pageNum
                        } else {
                          this.pageNum < this.imgRights.length && ++this.pageNum
                        }
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pageNum,
                        expression: "pageNum",
                      },
                    ],
                    staticClass: "fun-input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.pageNum },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.pageNum = $event.target.value
                        },
                        _vm.pageChange,
                      ],
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "/" +
                        _vm._s(this.imgLefts.length || this.imgRights.length)
                    ),
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.size,
                          expression: "size",
                        },
                      ],
                      staticClass: "select_scale___2lG6l",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.size = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.handleSelect,
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "auto" } }, [
                        _vm._v("自动"),
                      ]),
                      _c("option", { attrs: { value: "0.1" } }, [
                        _vm._v("10%"),
                      ]),
                      _c("option", { attrs: { value: "0.2" } }, [
                        _vm._v("20%"),
                      ]),
                      _c("option", { attrs: { value: "0.3" } }, [
                        _vm._v("30%"),
                      ]),
                      _c("option", { attrs: { value: "0.4" } }, [
                        _vm._v("40%"),
                      ]),
                      _c("option", { attrs: { value: "0.5" } }, [
                        _vm._v("50%"),
                      ]),
                      _c("option", { attrs: { value: "0.6" } }, [
                        _vm._v("60%"),
                      ]),
                      _c("option", { attrs: { value: "0.7" } }, [
                        _vm._v("70%"),
                      ]),
                      _c("option", { attrs: { value: "0.8" } }, [
                        _vm._v("80%"),
                      ]),
                      _c("option", { attrs: { value: "0.9" } }, [
                        _vm._v("90%"),
                      ]),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("100%")]),
                      _c("option", { attrs: { value: "2" } }, [_vm._v("200%")]),
                      _c("option", { attrs: { value: "3" } }, [_vm._v("300%")]),
                    ]
                  ),
                  _c("a-slider", {
                    attrs: { min: 10, id: "test", disabled: false },
                    on: { change: _vm.sliderChange },
                    model: {
                      value: _vm.sliderValue,
                      callback: function ($$v) {
                        _vm.sliderValue = $$v
                      },
                      expression: "sliderValue",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v("\n          对比容差：\n        "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.pixelToleranceLevel,
                        expression: "formData.pixelToleranceLevel",
                      },
                    ],
                    staticClass: "fun-input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.formData.pixelToleranceLevel },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.formData,
                          "pixelToleranceLevel",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "15px" } }, [
                    _vm._v("\n          标记区域：\n        "),
                  ]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.threshold,
                        expression: "formData.threshold",
                      },
                    ],
                    staticClass: "fun-input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.formData.threshold },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "threshold", $event.target.value)
                      },
                    },
                  }),
                ],
                1
              ),
              _vm.screenWidth > 700
                ? _c(
                    "a-col",
                    { staticClass: "fun-right", attrs: { span: 6 } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            width: "100%",
                          },
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                staticStyle: {
                                  "font-size": "13px",
                                  margin: "auto 0",
                                  "min-width": "48px",
                                },
                                on: { click: _vm.diff },
                              },
                              [_vm._v("对比")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn",
                                staticStyle: {
                                  "font-size": "13px",
                                  margin: "auto 0",
                                  "margin-left": "0.8vw",
                                  "min-width": "48px",
                                },
                                on: { click: _vm.cencel },
                              },
                              [_vm._v("\n              清除\n            ")]
                            ),
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              staticStyle: {
                                "font-size": "13px",
                                margin: "auto 0",
                                "margin-left": "0.8vw",
                                "min-width": "48px",
                              },
                              on: { click: _vm.handleLogout },
                            },
                            [_vm._v("\n            退出\n          ")]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "a-row",
                { staticStyle: { height: "100%" } },
                [
                  _c("a-col", { attrs: { span: _vm.openSpan[0] } }, [
                    _c("div", { staticClass: "show-div" }, [
                      _c("div", { staticClass: "show-head" }, [
                        _c(
                          "div",
                          { staticClass: "name" },
                          [
                            _c("span", [
                              _vm._v(
                                "【参考图片】" +
                                  _vm._s(
                                    _vm.fileLeftList.length > 0
                                      ? `${
                                          _vm.fileLeftList[_vm.pageNum - 1].name
                                        }${
                                          _vm.imgSizeLeftList[
                                            _vm.fileLeftList[_vm.pageNum - 1]
                                              .name
                                          ]
                                            ? "（" +
                                              _vm.imgSizeLeftList[
                                                _vm.fileLeftList[
                                                  _vm.pageNum - 1
                                                ].name
                                              ].imgWidth +
                                              "x" +
                                              _vm.imgSizeLeftList[
                                                _vm.fileLeftList[
                                                  _vm.pageNum - 1
                                                ].name
                                              ].imgHeight +
                                              "像素，" +
                                              _vm.imgSizeLeftList[
                                                _vm.fileLeftList[
                                                  _vm.pageNum - 1
                                                ].name
                                              ].size +
                                              "）"
                                            : ""
                                        }`
                                      : ""
                                  )
                              ),
                            ]),
                            _c("a-icon", {
                              staticClass: "close-icon",
                              attrs: { type: "close" },
                              on: {
                                click: function ($event) {
                                  return _vm.cencelOne("left")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "show-body",
                          style: "background-color:" + this.backGroundColor,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "show-img",
                              on: {
                                mouseover: _vm.imgLeftScroll,
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.rightClick.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm.imgLefts.length == 0
                                ? _c(
                                    "a-upload-dragger",
                                    {
                                      attrs: {
                                        accept: "image/*",
                                        name: "file",
                                        showUploadList: false,
                                        multiple: true,
                                        "before-upload": _vm.beforeLeftUpload,
                                      },
                                      on: { change: _vm.handleLeftChange },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "ant-upload-drag-icon",
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "folder-open" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "ant-upload-text" },
                                          [
                                            _vm._v(
                                              "\n                      将文件拖到此处，或点击该区域任意处打开\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "ant-upload-hint" },
                                          [
                                            _vm._v(
                                              "\n                      支持多张图片或单张图片上传\n                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "img-list" },
                                    _vm._l(_vm.imgLefts, function (item) {
                                      return _c("img", {
                                        key: item,
                                        attrs: { src: item },
                                      })
                                    }),
                                    0
                                  ),
                              _vm.isOpen
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "open",
                                      style: "opacity:" + _vm.openStyle,
                                      on: {
                                        mouseover: () => {
                                          this.openStyle = 0.9
                                        },
                                        mouseleave: () => {
                                          this.openStyle = 0
                                        },
                                        click: _vm.open,
                                      },
                                    },
                                    [_c("div", [_vm._v(">")])]
                                  )
                                : _vm._e(),
                              !_vm.isOpen
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "close",
                                      style: "opacity:" + _vm.openStyle,
                                      on: {
                                        mouseover: () => {
                                          this.openStyle = 0.9
                                        },
                                        mouseleave: () => {
                                          this.openStyle = 0
                                        },
                                        click: _vm.close,
                                      },
                                    },
                                    [
                                      _c("div", {
                                        domProps: { textContent: _vm._s("<") },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: _vm.openSpan[1] } }, [
                    _c("div", { staticClass: "show-div" }, [
                      _c("div", { staticClass: "show-head" }, [
                        _c(
                          "div",
                          { staticClass: "name" },
                          [
                            _vm._v(
                              "\n                【对比图片】\n                " +
                                _vm._s(
                                  _vm.fileRightList.length > 0
                                    ? `${
                                        _vm.fileRightList[_vm.pageNum - 1].name
                                      }${
                                        _vm.imgSizeRightList[
                                          _vm.fileRightList[_vm.pageNum - 1]
                                            .name
                                        ]
                                          ? "（" +
                                            _vm.imgSizeRightList[
                                              _vm.fileRightList[_vm.pageNum - 1]
                                                .name
                                            ].imgWidth +
                                            "x" +
                                            _vm.imgSizeRightList[
                                              _vm.fileRightList[_vm.pageNum - 1]
                                                .name
                                            ].imgHeight +
                                            "像素，" +
                                            _vm.imgSizeRightList[
                                              _vm.fileRightList[_vm.pageNum - 1]
                                                .name
                                            ].size +
                                            "）"
                                          : ""
                                      }`
                                    : ""
                                ) +
                                "\n                "
                            ),
                            _c("a-icon", {
                              staticClass: "close-icon",
                              attrs: { type: "close" },
                              on: {
                                click: function ($event) {
                                  return _vm.cencelOne("right")
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "show-body",
                          style: "background-color:" + this.backGroundColor,
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "show-img",
                              on: {
                                mouseover: _vm.imgRightScroll,
                                contextmenu: function ($event) {
                                  $event.preventDefault()
                                  return _vm.rightClick.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _vm.imgRights.length == 0
                                ? _c(
                                    "a-upload-dragger",
                                    {
                                      attrs: {
                                        name: "file",
                                        accept: "image/*",
                                        showUploadList: false,
                                        multiple: true,
                                        "before-upload": _vm.beforeRightUpload,
                                        disabled: !_vm.leftArr.length,
                                      },
                                      on: { change: _vm.handleRightChange },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "ant-upload-drag-icon",
                                          },
                                          [
                                            _c("a-icon", {
                                              attrs: { type: "folder-open" },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "ant-upload-text" },
                                          [
                                            _vm._v(
                                              "\n                      将文件拖到此处，或点击该区域任意处打开\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          { staticClass: "ant-upload-hint" },
                                          [
                                            _vm._v(
                                              "\n                      支持多张图片或单张图片上传\n                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "img-list" },
                                    [
                                      _vm._l(_vm.imgRights, function (item) {
                                        return _c("img", {
                                          key: item,
                                          attrs: { src: item },
                                        })
                                      }),
                                      _vm.diffs.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "div-list" },
                                            _vm._l(
                                              _vm.diffs,
                                              function (data, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    style: _vm.getSize(
                                                      _vm.styleHeightList[i]
                                                    ),
                                                  },
                                                  [
                                                    data.rectangles != null
                                                      ? _vm._l(
                                                          data.rectangles,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c("div", {
                                                              key: index,
                                                              ref:
                                                                item.minPoint
                                                                  .y +
                                                                "" +
                                                                index,
                                                              refInFor: true,
                                                              staticClass:
                                                                "diff-div",
                                                              style: `left:${
                                                                item.minPoint.x
                                                              }px;top:${
                                                                item.minPoint.y
                                                              }px;width:${
                                                                item.maxPoint
                                                                  .x -
                                                                item.minPoint.x
                                                              }px;height:${
                                                                item.maxPoint
                                                                  .y -
                                                                item.minPoint.y
                                                              }px;min-width:10px;min-height:10px;`,
                                                            })
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("a-col", { attrs: { span: _vm.openSpan[2] } }, [
                    _c("div", { staticClass: "show-div" }, [
                      _c("div", { staticClass: "show-head" }, [
                        _c("div", { staticClass: "name" }),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "span-body",
                          staticStyle: { "background-color": "#d9dce0" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "14px",
                                padding: "12px 19px",
                              },
                            },
                            [_vm._v("对比结果" + _vm._s(_vm.getDiffLength()))]
                          ),
                          _vm.diffs.length > 0
                            ? _c(
                                "a-collapse",
                                {
                                  model: {
                                    value: _vm.activeKey,
                                    callback: function ($$v) {
                                      _vm.activeKey = $$v
                                    },
                                    expression: "activeKey",
                                  },
                                },
                                [
                                  _vm._l(
                                    _vm.fileRightList,
                                    function (item, index) {
                                      return [
                                        item.rectangles != null
                                          ? _c(
                                              "a-collapse-panel",
                                              {
                                                key: index + "",
                                                attrs: { header: item.name },
                                              },
                                              [
                                                item.rectangles
                                                  ? _vm._l(
                                                      item.rectangles,
                                                      function (file, i) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            key: i,
                                                            staticStyle: {
                                                              cursor: "pointer",
                                                              "padding-left":
                                                                "24px",
                                                              "margin-bottom":
                                                                "1vh",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target !==
                                                                  $event.currentTarget
                                                                )
                                                                  return null
                                                                return _vm.skip(
                                                                  file,
                                                                  index,
                                                                  i
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("a-icon", {
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "6px",
                                                                "padding-top":
                                                                  "4px",
                                                              },
                                                              attrs: {
                                                                type: _vm
                                                                  .isShow[index]
                                                                  .children[i]
                                                                  .isShow
                                                                  ? "eye-invisible"
                                                                  : "eye",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.showDiff(
                                                                      file,
                                                                      index,
                                                                      i
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                        第" +
                                                                _vm._s(i + 1) +
                                                                "处\n                      "
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { width: 350, centered: "", okText: "关闭" },
          on: {
            cancel: () => {
              this.visible = false
              this.errorList = [
                { title: "参考图片", list: [] },
                { title: "对比图片", list: [] },
              ]
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c("a-icon", {
                  staticStyle: { color: "#faad14" },
                  attrs: { type: "question-circle" },
                }),
                _c("span", { staticStyle: { "padding-left": "2px" } }, [
                  _vm._v("提示"),
                ]),
              ],
              1
            ),
          ]),
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm._v(_vm._s(_vm.modalTitle)),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "space-between",
                display: "flex",
                width: "100%",
              },
            },
            [
              _vm.errorList[0].list.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "3px 0",
                            "font-weight": "700",
                            "text-align": "center",
                            "padding-top": "17px",
                          },
                        },
                        [_vm._v("参考图片")]
                      ),
                      _vm._l(_vm.errorList[0].list, function (item) {
                        return _c(
                          "div",
                          {
                            key: item,
                            staticStyle: {
                              padding: "3px 0",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.errorList[1].list.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "3px 0",
                            "font-weight": "700",
                            "text-align": "center",
                            "padding-top": "17px",
                          },
                        },
                        [_vm._v("对比图片")]
                      ),
                      _vm._l(_vm.errorList[1].list, function (item) {
                        return _c(
                          "div",
                          {
                            key: item,
                            staticStyle: {
                              padding: "3px 0",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "a-button",
                  {
                    key: "back",
                    on: {
                      click: () => {
                        this.visible = false
                        this.errorList = [
                          { title: "参考图片", list: [] },
                          { title: "对比图片", list: [] },
                        ]
                      },
                    },
                  },
                  [_vm._v("\n          取消\n        ")]
                ),
                _vm.modalTitle == "两边图片尺寸一致，但文件名不一致。"
                  ? _c(
                      "a-button",
                      {
                        key: "submit",
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.getDiff()
                          },
                        },
                      },
                      [_vm._v("\n          继续对比\n        ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }